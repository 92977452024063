import * as React from "react"
import standardFeatures from './../data/standard-features';
import List from './../components/side-list';

import Layout from './page-layout';



export default ({pageContext}) => {
  const slug = pageContext.address.toLowerCase().replace(/ /g, '-');
  const front = require(`../assets/images/properties/${slug}/front.jpg`);

  let listingClassName = 'label';
  let listingText = pageContext.salePrice;

  if (pageContext.isSold) {
      listingClassName += ' sold';
      listingText = 'Sold';
  } else if (pageContext.salePrice === '0') {
      listingClassName += ' sold';
      listingText = 'Under Contract';
  }

  let gallery = pageContext.gallery ? (<div className="col-lg-12">
    <h2 className="page-header">Gallery</h2>
    <div className="gallery">
      { pageContext.gallery.map(g => {
        const imageUrl = require(`../assets/images/properties/${slug}/${g}.jpg`);
        const thumb = require(`../assets/images/properties/${slug}/thumbnails/${g}.jpg`)
        return ( 
        <dl className="gallery-item" key={g}>
          <dt className="gallery-icon landscape">
            <a href={imageUrl}>
              <img width="150" height="150" src={thumb} className="attachment-thumbnail size-thumbnail" alt="" />
            </a>
          </dt>
        </dl>);
      }) }
      
      <br style={{"clear": "both"}}/>
    </div>
  </div>) : '';


  return (
      <Layout title={pageContext.address}>
        <div className="row">
          <div className="col-md-8 label-container">
            <span className={listingClassName}>{listingText}</span>
            <img className="img-responsive" src={front} alt="" />
          </div>
          <div className="col-md-4">
            {pageContext.lists.map(l => List(l, slug))}
            {List(standardFeatures)}
          </div>
        </div>

        <div className="row">
          {gallery}
        </div>
      </Layout>
    
  )
}

